import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { PublicationIcon, PublicationOutlineIcon, PurchasesFillIcon, PurchasesOutlineIcon, StarFillIcon, StarOutlineIcon } from "../../components/Icons";
import NewHeader from "../../components/NewHeader";
import { Separator, VSeparator } from "../../components";
import { BannerCard, SuperFanCard } from "../../components/Cards";
import { ROOT, previewPath } from "../../utils/Routes";
import useHeight from "../../utils/height";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { SmartUrl } from "../../utils";
import { LoadingLogo } from "../../components/Landing/LoadingLogo";
import { Icons } from "../../assets";
import ampli from "../../ampli";
import firebase from 'firebase/compat/app';

function filterContent(value, name) {
  return (
    (value.type === 'audio' || value.type === 'video') &&
    value.creator?.slug_field?.toLowerCase() === name.toLowerCase()
  )
}

export default function CreatorProfile() {
  const { height } = useHeight()
  const { name } = useParams()
  const [menu, setMenu] = useState(0)
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [publications, setPublications] = useState([])
  const [purchases, setPurchases] = useState([])
  const [superfans, setSuperfans] = useState([])

  useEffect(() => {
    setPublications([])
    setPurchases([])
    setSuperfans([])
    setMenu(0)
    if (name) {
      getUser(name)
      getPublications(name)
      getPurchases(name)
    }
  }, [name]) // Dependency array updated to include 'name'

  let c = 0
  async function getUser(uid) {
    c++
    setLoading(true);
    try {
      if (c === 1) {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        setLoading(false);
        if (docSnap.exists()) {
          const uData = docSnap.data();
          ampli.creatorProfile({
            id: name,
            name: uData?.name,
          })
          setData(uData);
          return uData;
        }
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return null;
    } finally {
      setLoading(false);
    }
  }


  const getPublications = async (uid) => {
    try {
      const q = query(collection(db, 'FeedsVertical'), where('userId', '==', uid), where('isVisible', '==', true), where('isDeleted', '==', false), where('isDisabled', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPublications(fetchedPosts);
      if (fetchedPosts.length > 0) {
        setMenu(0)
        let superfans_ = await fetchedPosts.filter(i => i.superfan?.length > 0)
        if (superfans_) {
          let data = await Promise.all(superfans_.map(async (i) => {
            let res = await getDoc(i.superfan[0]?.userRef)
            return { ...i, superfan: res.exists() ? res.data() : null }
          }))
          setSuperfans(data)
        }
      }
    } catch (error) {
      console.error("Failed to fetch publications:", error);
    }
  };

  const getPurchases = async (uid) => {
    try {
      const q = query(collection(db, 'subscriptions'), where('purchaserId', '==', uid));
      const querySnapshot = await getDocs(q);
      const fetchedPosts = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const purchaseData = doc.data();
          const productRef = purchaseData.product;
          const productDoc = await getDoc(productRef);
          const productData = productDoc.data();
          return {
            id: doc.id,
            ...purchaseData,
            product: productData,
          };
        })
      );
      setPurchases(fetchedPosts)
      // Handle the retrieved purchases data as needed
    } catch (error) {
      console.error("Failed to fetch purchases:", error);
    }
  };

  const contentData = data?.items?.filter(v => filterContent(v, name))
  //const productsData = merchData?.events

  if (loading) {
    return <LoadingLogo />
  }

  return (
    <div className="relative bg-white overflow-auto font-interRegular" style={{ height }}>
      <div className="absolute top-0 left-0 right-0 z-10">
        <NewHeader back to={ROOT} noLogo hideMenu />
      </div>
      <div style={{ height: 300, marginTop: -1, backgroundColor: '#D9D9D9' }}>
        <img
          src={data?.photo ? SmartUrl(data?.photo) : Icons.emptyUser} alt="photo" className={`w-full h-full ${data?.photo ? 'object-cover' : 'object-contain h-[85%] pt-12 pb-12'}  object-center`} />
      </div>
      <div className="px-8">
        <div className="text-center my-5 font-bold text-lg">{data?.name}</div>
        <div>
          <div className="flex justify-around" >
            <div className="p-2 text-center" onClick={() => setMenu(0)} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              {/* <ContentIcon dark={menu === 0} /> */}
              {menu === 0 ? <PublicationIcon /> : <PublicationOutlineIcon />}
              <div className="text-[8px] mt-1">Publicaciones</div>
            </div>
            {purchases?.length > 0 && <>
              <VSeparator />
              <div className="p-2 text-center" onClick={() => setMenu(1)} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {menu === 1 ? <PurchasesFillIcon /> : <PurchasesOutlineIcon />}
                <div className="text-[8px] mt-1">Compras</div>
              </div>
            </>}
            {superfans?.length > 0 && <>
              <VSeparator />
              <div className="p-2 text-center" onClick={() => setMenu(2)} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {menu === 2 ? <StarFillIcon /> : <StarOutlineIcon />}
                <div className="text-[8px] mt-[-4px]">Superfans</div>
              </div>
            </>}
          </div>
          <Separator />
        </div>

        {/* <div className="p-4 flex justify-center items-center">
          <PublicationIcon />
          <div className="text-sm ml-2">Publicaciones</div>
        </div> */}
        <Separator />
        <div className="mt-6">
          {/* {menu === 0 && <MyContent data={publications} />} */}
          {menu === 0 && <MyContent data={publications} />}
          {menu === 1 && <MyProducts data={purchases} artist={name} />}
          {menu === 2 && <SuperFans data={superfans} creator={data} />}
        </div>
      </div>
    </div>
  )
}

const MyContent = ({ data }) => {
  return (
    <div>
      {data?.length === 0 && <EmptyMessage value="No hay publicaciones" />}
      {data?.map((item, index) => <BannerCard
        isAudio={item?.isAudio}
        key={index}
        name={item.creator?.name}
        bgImage={item?.cover ? SmartUrl(item?.cover) : SmartUrl(item?.banner)}
        to={previewPath(item.userId, item.id)}
        title={item.title}
        description={item.description}
      />)}
    </div>
  )
}

const MyProducts = ({ data, artist }) => (
  <div>
    {data?.length === 0 && <EmptyMessage value="No hay Purchases" />}
    {data?.map((item, index) => <BannerCard
      isAudio={item?.product?.isAudio}
      key={index}
      name={item.creator?.name}
      bgImage={item?.product?.cover ? SmartUrl(item?.product?.cover) : SmartUrl(item?.product?.banner)}
      to={previewPath(item?.product?.userId, item?.product?.productId)}
      title={item?.product?.title}
      description={item?.product?.description}
    />)}
  </div>
)
const SuperFans = ({ data, creator }) => {
  return (
    <div>
      {data?.length === 0 && <EmptyMessage value="No hay Superfans" />}
      {data?.map((item, index) => <SuperFanCard
        item={item}
        creator={creator}
        key={index}
        name={item?.creator?.name}
        bgImage={item?.product?.cover ? SmartUrl(item?.product?.cover) : SmartUrl(item?.product?.banner)}
        to={previewPath(item?.userId, item?.productId)}
        title={item?.product?.title}
        description={item?.product?.description}
      />)}
    </div>

  )
}
const EmptyMessage = ({ value }) => (
  <div className="flex items-center justify-center font-bold text-[#A8A8A8] min-h-[200px]">
    {value}
  </div>
)