import { Link, useNavigate } from "react-router-dom";
import Swipe from "./Swipe";
import { Label } from ".";
import { ButtonGreyCircle, CircleButton } from "./NewButtons";
import { AudioIcon, HeartIcon, SoundBlackIcon, SoundWhiteIcon, VideoBlackIcon, VideoIcon, VideoWhiteIcon } from "./Icons";
import { SmartUrl } from "../utils";
import { Icons } from "../assets";
import { creatorProfilePath } from "../utils/Routes";

export function NewsCard({ name, bgImage, to, title, description, label, right }) {
  const navigate = useNavigate()

  const bgStyle = {
    backgroundImage: bgImage && `url(${bgImage})`,
    backgroundColor: !bgImage && '#DCDCDC'
  }

  const handleOnSwipe = () => {
    navigate(to);
  };

  return (
    <Link to={to}>
      <div className='relative h-36 mb-5'>
        <Swipe onSwipe={handleOnSwipe}>
          <div className='bg-cover flex items-end h-36 rounded-xl w-full' style={bgStyle}>
            {label &&
              <div className="absolute top-6 left-6">
                <Label value={label} />
              </div>
            }
            <div className='w-full py-4 pl-4 flex justify-between items-end'>
              <div className={bgImage ? "text-white" : "text-black"}>
                <p className='text-xl font-interBold'>{name}</p>
                <div className='font-interRegular text-sm mb-2'>
                  <i>{description}</i>
                </div>
                <div className='font-interRegular text-xs w-48'>
                  {title}
                </div>
              </div>
              {right &&
                <div className="absolute right-6 top-3">
                  <img src={right} alt="right" className="object-cover h-32" />
                </div>
              }
              {/* <ArrowButton light large size={28} className='h-14 w-14' /> */}
            </div>
          </div>
        </Swipe>
      </div >
    </Link >
  );
}



export function HomeCard({ name, bgImage, to, label, right }) {
  const navigate = useNavigate()

  const bgStyle = {
    backgroundImage: bgImage && `url(${bgImage})`,
    backgroundColor: !bgImage && '#DCDCDC'
  }

  const handleOnSwipe = () => {
    navigate(to);
  };

  return (
    <Link to={to}>
      <div className='relative h-36 mb-5'>
        <Swipe onSwipe={handleOnSwipe}>
          <div className='bg-cover flex items-end h-36 rounded-xl w-full' style={bgStyle}>
            {label &&
              <div className="absolute top-6 left-6">
                <Label value={label} />
              </div>
            }
            <div className='w-full py-4 pl-4 flex justify-between items-end'>
              <div className={bgImage ? "text-white" : "text-black"}>
                <p className='text-xl font-interBold'>{name}</p>
              </div>
              {right &&
                <div className="absolute right-6 top-3">
                  <img src={right} alt="right" className="object-cover h-32" />
                </div>
              }
              {/* <ArrowButton light large size={28} className='h-14 w-14' /> */}
            </div>
          </div>
        </Swipe>
      </div >
    </Link >
  );
}

export function BannerCard({ name, bgImage, to, title, description, isAudio }) {
  const navigate = useNavigate()

  const handleOnSwipe = () => {
    navigate(to);
  };

  return (
    <Link to={to}>
      <div className='h-[220px] font-interRegular'>
        <Swipe onSwipe={handleOnSwipe}>
          <div className='w-full h-[180px]'>
            <img src={bgImage} alt={'photo'} className="rounded-xl w-full h-[160px] object-cover object-center" />
            {/* <div className='w-full rounded-xl h-[160px] bg-cover ' style={{ backgroundImage: `url(${bgImage})` }}> */}
            {/* </div> */}
            <div className='w-full py-3 flex justify-between text-black'>
              <div className="flex items-center">
                {isAudio ? <SoundBlackIcon /> :
                  <div >
                    <VideoBlackIcon />
                  </div>
                }
                <div className="ml-2">
                  <div className="font-bold text-sm">{title}</div>
                  <div className="italic text-xs">{description}</div>
                </div>
              </div>
              <ButtonGreyCircle>
                <HeartIcon />
              </ButtonGreyCircle>
            </div>
          </div>
        </Swipe>
      </div >
    </Link >
  );
}

export function SuperFanCard({ item, name, creator, to, }) {
  const navigate = useNavigate()
  const superfan = item?.superfan
  const handleOnSwipe = () => {
    // navigate(to);
  };

  let newUser = superfan?.name?.replace(/\s/g, '').toLocaleLowerCase()
  // <Link >
  return (
    <Link to={to}>
      <div className='h-[220px] font-interRegular'>
        <Swipe onSwipe={handleOnSwipe}>
          <div className='w-full h-[180px]'>
            <div className="relative">
              <div className="relative">
                <div className="absolute inset-0 rounded-xl flex " style={{ alignItems: 'flex-end', width: '100%', background: 'linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,1),rgba(0,0,0,0.3), rgba(0,0,0,.0))' }}>
                  <div className="text-white text-sm ml-4 " style={{ marginBottom: 16 }}>
                    <div className="font-bold" style={{ lineHeight: 0.8, fontSize: 14 }}>{'PRIMERA'}</div>
                    <div className="font-bold" style={{ marginBottom: 10, fontSize: 14 }}>{'COMPRA'}</div>
                    <div style={{ fontSize: 11, lineHeight: 0.8, }}>"{(item?.title)?.toUpperCase()}"</div>
                    <div className="font-semibold" style={{ fontSize: 11 }}>{creator?.name}</div>
                  </div>
                </div>
                <img src={superfan?.photo ? SmartUrl(superfan?.photo) : 'https://d33heirl6jmsf6.cloudfront.net/bg.png'} alt={'photo'} className="w-[65%] h-[160px] object-cover object-center rounded-xl ml-auto" />
              </div>
            </div>
            <div className='w-full py-3 flex justify-between text-black'>
              <Link to={creatorProfilePath(item?.superfan?.userId)}>
                <div
                  className="flex items-left" style={{ flexDirection: 'column' }}>
                  <div className="font-bold text-sm">{superfan?.name}</div>
                  <div className="font-regular text-xs text-[#999]">{`@${newUser}`}</div>
                </div>
              </Link>
              <ButtonGreyCircle>
                <HeartIcon />
              </ButtonGreyCircle>
            </div>
          </div>
        </Swipe>
      </div >
    </Link >
  );
}